import moment from 'moment';
import React from 'react';

const Foot = () => {
  return (
    <div className='text-center text-sm text-gray-500'>
      © {moment().format('YYYY')} GTE, All rights reserved.
    </div>
  );
};

export default Foot;
