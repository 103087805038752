import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, message, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { baseURL, imgURL } from '../lib/common';
import AliOSSUpload from './AliOSSUpload';

const { TextArea } = Input;

const CollectionDetail = () => {
  const navigate = useNavigate();
  const { collectionID } = useParams();
  console.log('collectionID: ', collectionID);

  const [loading, setLoading] = useState(true);
  const [collection, setCollection] = useState({});
  const [form] = Form.useForm();

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const onFinish = async (c) => {
    if (c.newIntroImg) {
      c.introImg = c.newIntroImg[0]?.response?.name;
      delete c.newIntroImg;
    } else {
      c.introImg = collection.introImg;
    }

    if (c.newCoverImg) {
      c.coverImg = c.newCoverImg[0]?.response?.name;
      delete c.newCoverImg;
    } else {
      c.coverImg = collection.coverImg; // btoe/collections/c07.jpeg
    }

    if (collectionID) {
      c.id = collection.id;
      c.createdAt = collection.createdAt;
      c.status = collection.status;
    }

    console.log(c);

    const url = `${baseURL}/collection`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(c),
    };

    const res = await fetch(url, options);
    const data = await res.json();
    console.log(data);
    if (data.success) {
      message.success(data.message);
      setTimeout(() => {
        navigate('/collection', { replace: true });
      }, 2000);
    } else {
      message.success(data.message);
    }
  };

  const normFile = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (collectionID) {
      const requestURL = `${baseURL}/collection/${collectionID}`;
      console.log(requestURL);

      fetch(requestURL)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            console.log(data.data.collection);
            setCollection(data.data.collection);
            form.resetFields();
            setLoading(false);
          }
        });
    } else {
      setLoading(false);
    }
  }, [form, collectionID]);

  // 确保collection更新的时候，自动重载表单数据
  useEffect(() => form.resetFields(), [collection]);

  return (
    <div className='space-y-3'>
      <h1 className='text-xl'>
        {collectionID ? (
          <>
            编辑藏品{' '}
            <span className='text-xs text-neutral-600'>
              (ID: {collectionID})
            </span>
          </>
        ) : (
          '新建藏品'
        )}
      </h1>
      <Spin spinning={loading}>
        <Form
          initialValues={collection}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout='horizontal'
          form={form}
          onFinish={onFinish}>
          <Form.Item
            name='name'
            label='藏品名称'
            rules={[
              { required: true, message: '请输入藏品名称' },
              { whitespace: true, message: '不能输入空格' },
              { min: 1, message: '不能少于1个汉字' },
              { max: 20, message: '不能大于20个汉字' },
            ]}>
            <Input />
          </Form.Item>

          {collection.coverImg && (
            <Form.Item label='原藏品图片'>
              <div className='w-28 relative object-cover overflow-hidden rounded'>
                <Image
                  src={imgURL + collection.coverImg}
                  alt={collection.name}
                />
              </div>
            </Form.Item>
          )}

          <Form.Item
            label='藏品图片'
            name='newCoverImg'
            //子节点的值的属性，'fileList' 是 getValueFromEvent 指定的函数的返回值。
            valuePropName='fileList'
            //设置如何将 event 的值转换成字段值， normFile是子元素相关事件的回调函数
            getValueFromEvent={normFile}>
            <AliOSSUpload
              dir='btoe/collections/'
              listType='picture-card'
              maxCount={1}>
              <div>
                <PlusOutlined />
                <div className='mt-2'>上传</div>
              </div>
            </AliOSSUpload>
          </Form.Item>
          <Form.Item
            name='creatorName'
            label='创作方'
            rules={[
              { required: true, message: '请输入创作方名称' },
              { whitespace: true, message: '不能输入空格' },
              { min: 1, message: '不能少于1个汉字' },
              { max: 127, message: '不能大于127个汉字' },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='merchantName'
            label='发行方'
            rules={[
              { required: true, message: '请输入发行方名称' },
              { whitespace: true, message: '不能输入空格' },
              { min: 1, message: '不能少于1个汉字' },
              { max: 127, message: '不能大于127个汉字' },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='num'
            label='发行数量'
            rules={[
              { required: true, message: '请输入藏品发行数量' },
              {
                type: 'number',
                transform(value) {
                  if (value) {
                    return Number(value);
                  }
                },
                min: 0,
                message: '只能输入数字',
              },
            ]}>
            {collectionID ? <Input disabled /> : <Input />}
          </Form.Item>
          <Form.Item
            name='inventoryQuantity'
            label='库存数量'
            dependencies={['num']}
            hasFeedback
            rules={[
              { required: true, message: '请输入藏品库存数量' },
              {
                type: 'number',
                transform(value) {
                  if (value) {
                    return Number(value);
                  }
                },
                min: 0,
                message: '只能输入数字',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (collectionID) {
                    return Promise.resolve();
                  }

                  if (!value || getFieldValue('num') <= value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error('库存数不能小于发行数量，请重新输入')
                  );
                },
              }),
            ]}>
            {collectionID ? <Input disabled /> : <Input />}
          </Form.Item>
          <Form.Item name='type' label='藏品类型'>
            <Select
              defaultValue='0'
              options={[
                {
                  value: '0',
                  label: '普通数字藏品',
                },
                {
                  value: '1',
                  label: '杜比数字藏品',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name='statementShort'
            label='免责声明(简略)'
            rules={[{ max: 127, message: '不能大于127个汉字' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='statementLong'
            label='免责声明'
            rules={[{ max: 127, message: '不能大于127个汉字' }]}>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name='intro'
            label='详情介绍'
            rules={[{ max: 512, message: '不能大于512个汉字' }]}>
            <TextArea rows={6} />
          </Form.Item>
          {collection.introImg && (
            <Form.Item label='原藏品详情图片'>
              <div className='w-28 h-28 relative object-cover overflow-hidden rounded'>
                <Image
                  src={imgURL + collection.introImg}
                  alt={collection.name}
                />
              </div>
            </Form.Item>
          )}
          <Form.Item
            label='藏品详情图片'
            name='newIntroImg'
            valuePropName='fileList'
            getValueFromEvent={normFile}>
            <AliOSSUpload
              dir='btoe/detail/'
              listType='picture-card'
              maxCount={1}>
              <div>
                <PlusOutlined />
                <div className='mt-2'>上传</div>
              </div>
            </AliOSSUpload>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type='primary' htmlType='submit' className='mr-2'>
              提交
            </Button>
            <Button htmlType='button' onClick={onReset}>
              重置
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default CollectionDetail;
