import { Avatar, Layout } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.svg';

const { Header } = Layout;

const Head = () => {
  return (
    <Header className='header'>
      <div className='flex justify-between'>
        <div>
          <Link to='/'>
            <img src={logo} alt='logo' className='w-20' />
          </Link>
        </div>
        <div className='flex items-center space-x-2'>
          <Avatar
            src='https://joeschmoe.io/api/v1/random'
            className='border-2 border-white/60'
          />
          <div className='text-white'>Elton</div>
        </div>
      </div>
    </Header>
  );
};

export default Head;
