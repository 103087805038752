import SparkMD5 from 'spark-md5';

const md5 = (file) => {
  const readAsArrayBuffer = (data) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(data);
      reader.onload = (evt) => {
        const body = evt.target?.result;
        resolve(body);
      };
      reader.onerror = () => {
        reject();
      };
    });
  };

  const computeMd5 = async (file) => {
    const Md5ChunkSize = 2097152;
    let currentChunk = 0;
    const chunks = Math.ceil(file.size / Md5ChunkSize);
    const spark = new SparkMD5.ArrayBuffer();

    while (currentChunk < chunks) {
      const start = currentChunk * Md5ChunkSize;
      const end =
        start + Md5ChunkSize >= file.size ? file.size : start + Md5ChunkSize;

      const buffer = await readAsArrayBuffer(file.slice(start, end));
      spark.append(buffer);
      currentChunk++;
    }

    return spark.end();
  };

  return computeMd5(file);
};

export default md5;
