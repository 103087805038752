import { Button, Collapse, DatePicker, Form, Space, Table } from 'antd';
import { Link } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/zh-cn';
import React, { useEffect, useState } from 'react';
import { baseURL } from '../lib/common';

const CustomerMgt = () => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  //设置页码
  const [page, setPage] = useState(1);
  //设置每页条数
  const [size, setSize] = useState(10);
  const [filters, setFilters] = useState({});
  //设置总页数
  const [total, setTotal] = useState(0);

  const { RangePicker } = DatePicker;
  const { Panel } = Collapse;
  const dateFormat = 'YYYY/MM/DD';
  const rangeConfig = {
    rules: [
      {
        type: 'array',
        required: true,
        message: '请选择日期范围',
      },
    ],
  };

  //搜索条件
  let filter = {
    beginDate: '',
    endDate: '',
  };

  useEffect(() => {
    console.log({ page, size, filters });
    fetchData(page, size, filters);
  }, [page, size, filters]);

  //点击搜索按钮触发的方法
  const searchData = (values) => {
    console.log(values);

    if (values && values.create_time !== undefined) {
      const min_create_time = moment(values.create_time[0])
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      const max_create_time = moment(values.create_time[1])
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      filter.beginDate = min_create_time;
      filter.endDate = max_create_time;
    } else {
      filter.beginDate = moment()
        .subtract(7, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      filter.endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    setFilters(filter);
    setPage(1);
    console.log('search', page, size);
    console.log('search filter', filter);
  };

  //Api请求后台接口的方法
  const fetchData = async (page, size, filters) => {
    setLoading(true);
    filter.beginDate =
      filters.beginDate ||
      moment().startOf('day').subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');
    filter.endDate =
      filters.endDate || moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');

    const params = { page, size, ...filter };
    console.log('params: ', params);
    const requestURL = `${baseURL}/user/getUsers?${new URLSearchParams(
      Object.entries(params)
    ).toString()}`;
    console.log(requestURL);

    await fetch(requestURL)
      .then((resp) => resp.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          console.log(data.data.users);
          setUserList(data.data.users);
          setTotal(data.data.total);
        }
      });
  };

  //重置表单
  const [form] = Form.useForm();
  const reset = () => {
    form.resetFields();
    //此处尤其要注意，resetFields表单重置后会重新mount组件，因此不必在这里重新调用请求api的接口。
    //重置之后，会自动调用搜索列表的方法。
  };

  //表头列
  const columns = [
    {
      title: 'UserID',
      dataIndex: 'userId',
      render: (text) => <a href='/'>{text}</a>,
    },
    {
      title: 'OpenID',
      dataIndex: 'openId',
    },
    {
      title: 'UnionID',
      dataIndex: 'openId',
    },
    {
      title: '数字凭证数量',
      dataIndex: 'numOfEvidence',
      render: (_, record) => (
        <Link to={`/myCollection/${record.userId}`}>
          {record.numOfEvidence}
        </Link>
      ),
    },
    {
      title: '注册时间',
      dataIndex: 'createAt',
      render: (date) => <>{moment(date).format('YYYY/MM/DD HH:mm')}</>,
    },
    {
      title: '操作',
      render: (_, record) => (
        <Space size='middle'>
          <Link to={`/myCollection/${record.userId}`}>查看</Link>
        </Space>
      ),
    },
  ];

  return (
    <div className='space-y-3'>
      <h1 className='text-xl'>客户管理</h1>
      <Collapse defaultActiveKey={['1']}>
        <Panel header='搜索条件' key='1'>
          <Form
            className='list'
            autoComplete='off'
            layout='inline'
            name='basic'
            form={form}
            initialValues={{ remember: true }}
            onFinish={searchData}>
            <Form.Item label='注册时间' name='create_time'>
              <RangePicker
                defaultValue={[
                  moment(new Date(), dateFormat).subtract(1, 'weeks'),
                  moment(new Date(), dateFormat),
                ]}
                format={dateFormat}
                className='ml-2'
                {...rangeConfig}
              />
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
              <Button htmlType='submit' className='reset ml-2' onClick={reset}>
                重置
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>

      <Table
        columns={columns}
        dataSource={userList}
        loading={loading}
        rowKey={(record) => record['userId']}
        onChange={({ current, pageSize }) => {
          console.log(current, pageSize);
          setPage(current);
          setSize(pageSize);
        }}
        pagination={{
          defaultPageSize: size,
          showTotal: () => {
            return `共${total}条数据`;
          },
          current: page,
          total: total,
        }}
      />
    </div>
  );
};

export default CustomerMgt;
