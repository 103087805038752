import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Space,
  Table,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/zh-cn';
import { baseURL } from '../lib/common';

const CollectionMgt = () => {
  const [collectionList, setCollectionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inventory, setInventory] = useState(0);
  const [collectionID, setCollectionID] = useState('');
  const { confirm } = Modal;
  //设置页码
  const [page, setPage] = useState(1);
  //设置每页条数
  const [size, setSize] = useState(10);
  const [filters, setFilters] = useState({});
  //设置总页数
  const [total, setTotal] = useState(0);

  const { RangePicker } = DatePicker;
  const { Panel } = Collapse;
  const dateFormat = 'YYYY/MM/DD';
  const rangeConfig = {
    rules: [
      {
        type: 'array',
        required: true,
        message: '请选择日期范围',
      },
    ],
  };

  //搜索条件
  let filter = {
    beginDate: '',
    endDate: '',
  };

  useEffect(() => {
    console.log({ page, size, filters });
    fetchData(page, size, filters);
  }, [page, size, filters]);

  //点击搜索按钮触发的方法
  const searchData = (values) => {
    console.log(values);

    if (values && values.create_time !== undefined) {
      const min_create_time = moment(values.create_time[0])
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      const max_create_time = moment(values.create_time[1])
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      filter.beginDate = min_create_time;
      filter.endDate = max_create_time;
    } else {
      filter.beginDate = moment()
        .subtract(3, 'month')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      filter.endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    setFilters(filter);
    setPage(1);
    console.log('search', page, size);
    console.log('search filter', filter);
  };

  //Api请求后台接口的方法
  const fetchData = async (page, size, filters) => {
    setLoading(true);
    filter.beginDate =
      filters.beginDate ||
      moment()
        .startOf('day')
        .subtract(7, 'month')
        .format('YYYY-MM-DD HH:mm:ss');
    filter.endDate =
      filters.endDate || moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');

    const params = { page, size, ...filter };
    console.log('params: ', params);
    const requestURL = `${baseURL}/collection/allCollections?${new URLSearchParams(
      Object.entries(params)
    ).toString()}`;
    console.log(requestURL);

    await fetch(requestURL)
      .then((resp) => resp.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          console.log(data.data.collections);
          setCollectionList(data.data.collections);
          setTotal(data.data.total);
        }
      });
  };

  //重置表单
  const [form] = Form.useForm();
  const reset = () => {
    form.resetFields();
    //此处尤其要注意，resetFields表单重置后会重新mount组件，因此不必在这里重新调用请求api的接口。
    //重置之后，会自动调用搜索列表的方法。
  };

  // 删除藏品
  const deleteCollection = async (id) => {
    const url = `${baseURL}/collection/${id}`;
    console.log('delete: ', url);
    await fetch(url, { method: 'DELETE' })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success) {
          message.success(data.message);
          setTimeout(() => {
            window.location.reload(false); // refresh current page.
          }, 2000);
        }
      });
  };

  // 增加库存
  const handleOk = async () => {
    setIsModalVisible(false);
    const url = `${baseURL}/collection/addInventory/${collectionID}?quantity=${inventory}`;
    console.log(url);
    await fetch(url, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          message.success(data.message);
          setTimeout(() => {
            window.location.reload(false); // refresh current page.
          }, 2000);
        }
      });
  };

  // 增加库存取消按钮
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showConfirm = (id) => {
    confirm({
      title: '确认删除此藏品吗?',
      icon: <ExclamationCircleOutlined />,
      content: `确认删除藏品(${id}吗？)`,

      async onOk() {
        console.log('OK');
        await deleteCollection(id);
      },

      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const showModal = (id) => {
    setIsModalVisible(true);
    setCollectionID(id);
  };

  const handleChange = (e) => {
    setInventory(e.target.value);
  };

  //表头列
  const columns = [
    {
      title: '藏品ID',
      dataIndex: 'id',
    },
    {
      title: '藏品名称',
      dataIndex: 'name',
    },
    {
      title: '创作者',
      dataIndex: 'creatorName',
    },
    {
      title: '发行方',
      dataIndex: 'merchantName',
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: (date) => <>{moment(date).format('YYYY/MM/DD HH:mm')}</>,
    },
    {
      title: '发行量',
      dataIndex: 'num',
      align: 'right',
      render: (num) => <>{num.toLocaleString('en-US')}</>,
    },
    {
      title: '领取量',
      align: 'right',
      render: (_, record) => <>{record.num - record.inventoryQuantity}</>,
    },
    {
      title: '操作',
      dataIndex: 'createAt',
      render: (_, record) => (
        <>
          <Space size='middle'>
            <Link to={`/collection/${record.id}`}>编辑</Link>
            <Button type='link' onClick={() => showConfirm(record.id)}>
              删除
            </Button>
            <Button type='link' onClick={() => showModal(record.id)}>
              添加库存
            </Button>
          </Space>
        </>
      ),
    },
  ];

  return (
    <div className='space-y-3'>
      <div className='flex items-center justify-between'>
        <h1 className='text-xl'>藏品管理</h1>
        <Button type='primary' href='/collection/new'>
          新建藏品
        </Button>
      </div>

      <Collapse defaultActiveKey={['1']}>
        <Panel header='搜索条件' key='1'>
          <Form
            className='list'
            autoComplete='off'
            layout='inline'
            name='basic'
            form={form}
            initialValues={{ remember: true }}
            onFinish={searchData}>
            <Form.Item label='创建时间' name='create_time'>
              <RangePicker
                defaultValue={[
                  moment(new Date(), dateFormat).subtract(3, 'month'),
                  moment(new Date(), dateFormat),
                ]}
                format={dateFormat}
                className='ml-2'
                {...rangeConfig}
              />
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
              <Button htmlType='submit' className='reset ml-2' onClick={reset}>
                重置
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>

      <Modal
        title='增加藏品库存'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <Form>
          <Form.Item label='库存数量：'>
            <Input
              placeholder='请输入要增加的库存数量'
              onChange={handleChange}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Table
        columns={columns}
        dataSource={collectionList}
        loading={loading}
        rowKey={(record) => record['id']}
        onChange={({ current, pageSize }) => {
          console.log(current, pageSize);
          setPage(current);
          setSize(pageSize);
        }}
        pagination={{
          defaultPageSize: size,
          showTotal: () => {
            return `共${total}条数据`;
          },
          current: page,
          total: total,
        }}
      />
    </div>
  );
};

export default CollectionMgt;
