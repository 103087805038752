import { Button, Collapse, DatePicker, Form, Modal, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import 'moment/locale/zh-cn';
import { baseURL } from '../lib/common';

const { Option } = Select;

const CouponMgt = () => {
  const [couponList, setCouponList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [creationCoupon, setCreationCoupon] = useState(null);
  const [form] = Form.useForm();

  //设置页码
  const [page, setPage] = useState(1);
  //设置每页条数
  const [size, setSize] = useState(10);
  const [filters, setFilters] = useState({});
  //设置总页数
  const [total, setTotal] = useState(0);

  const { RangePicker } = DatePicker;
  const { Panel } = Collapse;
  const dateFormat = 'YYYY/MM/DD';
  const rangeConfig = {
    rules: [
      {
        type: 'array',
        required: true,
        message: '请选择日期范围',
      },
    ],
  };

  //搜索条件
  let filter = {
    beginDate: '',
    endDate: '',
    isUsed: '',
  };

  useEffect(() => {
    console.log({ page, size, filters });
    fetchData(page, size, filters);
  }, [page, size, filters]);

  const onIsUsedChange = (value) => {
    console.log('selected: ', value);

    setFilters({ ...filters, isUsed: value });
    console.log(filters);
  };

  //点击搜索按钮触发的方法
  const searchData = (values) => {
    console.log(values);

    if (values && values.create_time !== undefined) {
      const min_create_time = moment(values.create_time[0])
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      const max_create_time = moment(values.create_time[1])
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      filter.beginDate = min_create_time;
      filter.endDate = max_create_time;
    } else {
      filter.beginDate = moment()
        .subtract(3, 'month')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      filter.endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    if (values.isUsed !== 'all') {
      console.log(values.isUsed);
      filters.isUsed = values.isUsed;
    }

    setFilters(filter);
    setPage(1);
    console.log('search', page, size);
    console.log('search filter', filters);
  };

  //Api请求后台接口的方法
  const fetchData = async (page, size, filters) => {
    setLoading(true);
    filter.beginDate =
      filters.beginDate ||
      moment()
        .startOf('day')
        .subtract(7, 'month')
        .format('YYYY-MM-DD HH:mm:ss');
    filter.endDate =
      filters.endDate || moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
    filter.isUsed = filters.isUsed || '';

    const params = { page, size, ...filter };
    console.log('params: ', params);
    const requestURL = `${baseURL}/coupon?${new URLSearchParams(
      Object.entries(params)
    ).toString()}`;
    console.log(requestURL);

    await fetch(requestURL)
      .then((resp) => resp.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          console.log(data.data.coupons);
          setCouponList(data.data.coupons);
          setTotal(data.data.total);
        }
      });
  };

  //重置表单
  const reset = () => {
    form.resetFields();
    //此处尤其要注意，resetFields表单重置后会重新mount组件，因此不必在这里重新调用请求api的接口。
    //重置之后，会自动调用搜索列表的方法。
  };

  const showModal = async () => {
    const url = `${baseURL}/coupon/create`;
    console.log(url);
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const coupons = await fetch(url, options)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success) {
          console.log(data.data.coupons);
          setIsModalVisible(true);
          return data.data.coupons;
        }
      });
    setCreationCoupon(coupons);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //表头列
  const columns = [
    {
      title: '兑换券编号',
      dataIndex: 'code',
    },
    {
      title: '状态',
      dataIndex: 'isUsed',
      render: (isUsed) => <>{isUsed ? '已兑换' : '未兑换'}</>,
    },
    {
      title: '藏品名称',
      dataIndex: 'collectionName',
    },
    {
      title: '藏家名',
      dataIndex: 'ownerName',
    },
    {
      title: '发行量',
      dataIndex: 'collectionNum',
      align: 'right',
      render: (num) => <>{num.toLocaleString('en-US')}</>,
    },
    {
      title: '库存',
      dataIndex: 'collectionInventoryQuantity',
      align: 'right',
      render: (num) => <>{num.toLocaleString('en-US')}</>,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      render: (date) => <>{moment(date).format('YYYY/MM/DD HH:mm:ss')}</>,
    },
    {
      title: '兑换时间',
      dataIndex: 'usedAt',
      render: (date) => (
        <>{date && moment(date).format('YYYY/MM/DD HH:mm:ss')}</>
      ),
    },
    {
      title: '存证时间',
      dataIndex: 'evidenceTime',
    },
  ];

  return (
    <div className='space-y-3'>
      <div className='flex items-center justify-between'>
        <h1 className='text-xl'>兑换券管理</h1>
        <Button type='primary' onClick={showModal}>
          新建兑换券
        </Button>
      </div>

      <Modal
        title='新建兑换券成功'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        {creationCoupon &&
          creationCoupon?.map((item) => (
            <div key={item.collectionId} className='mb-1'>
              <p>数字藏品：{item.collectionName}</p>
              <p>
                创建了{' '}
                <span className='text-cyan-700 font-mono'>
                  {item.codes.length}
                </span>{' '}
                张兑换券
              </p>
            </div>
          ))}
        {creationCoupon?.length === 0 && <p>没有可以创建兑换券的藏品</p>}
      </Modal>

      <Collapse defaultActiveKey={['1']}>
        <Panel header='搜索条件' key='1'>
          <Form
            className='list'
            autoComplete='off'
            layout='inline'
            name='basic'
            form={form}
            initialValues={{ remember: true }}
            onFinish={searchData}>
            <Form.Item label='创建时间' name='create_time'>
              <RangePicker
                defaultValue={[
                  moment(new Date(), dateFormat).subtract(3, 'month'),
                  moment(new Date(), dateFormat),
                ]}
                format={dateFormat}
                className='ml-2'
                {...rangeConfig}
              />
            </Form.Item>
            <Form.Item name='isUsed' label='兑换状态'>
              <Select defaultValue='all' onChange={onIsUsedChange}>
                <Option value='all'>全部</Option>
                <Option value='true'>是</Option>
                <Option value='false'>否</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
              <Button htmlType='submit' className='reset ml-2' onClick={reset}>
                重置
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>

      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <div className='pl-12'>
              {record.ownerName && <p>藏家名: {record.ownerName}</p>}
              {record.ownerPhone && <p>藏家电话: {record.ownerPhone}</p>}
              {record.ownerPhone && <p>userID: {record.userId}</p>}
              {record.openId && <p>openID: {record.openId?.toUpperCase()}</p>}
              {record.unionId && (
                <p>unionID: {record.unionId?.toUpperCase()}</p>
              )}
              {record.evidenceTime && <p>存证时间: {record.evidenceTime}</p>}
              {record.evidenceNo && (
                <p>存证编码: {record.evidenceNo?.toUpperCase()}</p>
              )}
              {record.evidenceTxhash && (
                <p>区块链ID: {record.evidenceTxhash?.toUpperCase()}</p>
              )}
            </div>
          ),
        }}
        dataSource={couponList}
        loading={loading}
        rowKey={(record) => record['id']}
        onChange={({ current, pageSize }) => {
          console.log(current, pageSize);
          setPage(current);
          setSize(pageSize);
        }}
        pagination={{
          defaultPageSize: size,
          showTotal: () => {
            return `共${total}条数据`;
          },
          current: page,
          total: total,
        }}
      />
    </div>
  );
};

export default CouponMgt;
