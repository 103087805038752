import { GiftOutlined, PictureOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Side = () => {
  let navigate = useNavigate();
  const selectedKey = useLocation().pathname;

  const items = [
    {
      key: '1',
      icon: React.createElement(UserOutlined),
      label: '客户管理',
      onClick: () => {
        navigate('/customer');
      },
    },
    {
      key: '2',
      icon: React.createElement(PictureOutlined),
      label: '藏品管理',
      onClick: () => {
        navigate('/collection');
      },
    },
    {
      key: '3',
      icon: React.createElement(GiftOutlined),
      label: '兑换券管理',
      onClick: () => {
        navigate('/coupon');
      },
    },
  ];

  const highlight = () => {
    if (/^\/customer/.test(selectedKey)) {
      return ['1'];
    } else if (/^\/collection/.test(selectedKey)) {
      return ['2'];
    } else if (/^\/coupon/.test(selectedKey)) {
      return ['3'];
    }
  };

  return (
    <Menu
      mode='inline'
      selectedKeys={highlight()}
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      className='h-full'
      items={items}
    />
  );
};

export default Side;
