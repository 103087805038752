import OSS from 'ali-oss';
import { Upload } from 'antd';
import React, { useCallback } from 'react';
import md5 from '../lib/md5';

const AliOSSUpload = (props) => {
  const { dir, children, ...restProps } = props;

  const ossConfig = {
    accessKeyId: 'LTAI5tKbSNyNnx3KzPu6di95',
    accessKeySecret: 'FlYo3QSnEDSUOCDq54HCl3rMAhv3ka',
    bucket: 'gteconnect-images',
    region: 'oss-cn-shanghai',
  };

  const getFilename = useCallback(
    (file, hash) => {
      const idx = file.name.lastIndexOf('.');
      let ext = '';
      if (idx !== -1) {
        ext = file.name.substr(idx).toLowerCase();
      }
      return `${dir}${hash}${ext}`;
    },
    [dir]
  );

  const upload = useCallback(
    (option) => {
      const { file, onProgress, onSuccess, onError } = option;

      md5(file).then((hash) => {
        const filename = getFilename(file, hash);
        const client = new OSS(ossConfig);

        return new Promise((resolve, reject) => {
          client
            .multipartUpload(filename, file, {
              progress: async (percent) => {
                onProgress({ percent: percent * 100 });
              },
            })
            .then((result) => {
              const ossPath = result.res.requestUrls[0].split('?')[0];
              const url = ossPath.replace(/^http:\/\//i, 'https://');
              const data = {
                status: result?.res?.status,
                name: result?.name,
                url: url,
              };
              onSuccess(data);
              console.log(data);
              resolve(result);
            })
            .catch((error) => {
              onError(error);
              reject(error);
            });
        });
      });
    },
    [ossConfig, md5, getFilename]
  );

  const customRequest = useCallback((option) => upload(option), [upload]);

  const uploadProps = {
    ...restProps,
    customRequest: props.customRequest ? props.customRequest : customRequest,
  };
  return <Upload {...uploadProps}>{children}</Upload>;
};

export default AliOSSUpload;
