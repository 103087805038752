import { Breadcrumb, Layout } from 'antd';
import React from 'react';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import CollectionDetail from './components/CollectionDetail';
import CollectionMgt from './components/CollectionMgt';
import CouponMgt from './components/CouponMgt';
import CustomerMgt from './components/CustomerMgt';
import Foot from './components/Foot';
import Head from './components/Head';
import MyCollection from './components/MyCollection';
import Side from './components/Side';
import './index.css';

const { Content, Footer, Sider } = Layout;

function App() {
  const breadcrumbNameMap = {
    // '/': '首页',
    '/customer': '客户管理',
    '/collection': '藏品管理',
    '/myCollection': '我的藏品',
    '/coupon': '兑换券管理',
  };

  const location = useLocation();

  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key='home'>
      <Link to='/'>首页</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <Layout className='h-full'>
      <Head />
      <Content className='px-12'>
        <Breadcrumb className='my-2'>{breadcrumbItems}</Breadcrumb>
        <Layout className='bg-white py-6'>
          <Sider className='bg-white w-48'>
            <Side />
          </Sider>
          <Content className='px-6 min-h-[280px]'>
            <Routes>
              <Route exact path='/' element={<Navigate to='/customer' />} />
              <Route path='/customer' element={<CustomerMgt />} />
              <Route path='/collection' element={<CollectionMgt />} />
              <Route
                path='/collection/:collectionID'
                element={<CollectionDetail />}
              />
              <Route path='/collection/new' element={<CollectionDetail />} />
              <Route path='/myCollection/:userID' element={<MyCollection />} />
              <Route path='/coupon' element={<CouponMgt />} />
            </Routes>
          </Content>
        </Layout>
      </Content>
      <Footer>
        <Foot />
      </Footer>
    </Layout>
  );
}

export default App;
