import { Table } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { baseURL } from '../lib/common';

const MyCollection = () => {
  const { userID } = useParams();
  console.log(userID);

  const [collectionList, setCollectionList] = useState([]);
  const [loading, setLoading] = useState(false);
  //设置页码
  const [page, setPage] = useState(1);
  //设置每页条数
  const [size, setSize] = useState(10);
  //设置总页数
  const [total, setTotal] = useState(0);

  useEffect(() => {
    console.log({ page, size, userID });
    fetchData(page, size, userID);
  }, [page, size, userID]);

  //Api请求后台接口的方法
  const fetchData = async (page, size, userID) => {
    setLoading(true);

    const params = { page, size, userId: userID };
    console.log('params: ', params);
    const requestURL = `${baseURL}/collection/myCollection?${new URLSearchParams(
      Object.entries(params)
    ).toString()}`;
    console.log(requestURL);

    await fetch(requestURL)
      .then((resp) => resp.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          console.log(data.data.myEvidences);
          setCollectionList(data.data.myEvidences);
          setTotal(data.data.total);
        }
      });
  };

  //表头列
  const columns = [
    {
      title: '藏品ID',
      dataIndex: 'collectionId',
    },
    {
      title: '藏品名称',
      dataIndex: 'collectionName',
    },
    {
      title: '藏家名',
      dataIndex: 'ownerName',
    },
    {
      title: '手机号',
      dataIndex: 'ownerPhone',
    },
    {
      title: '藏品有效期',
      dataIndex: 'evidenceValidPeriod',
      render: (date) => <>{moment(date).format('YYYY/MM/DD HH:mm:ss')}</>,
    },
    {
      title: '创作者',
      dataIndex: 'collectionAuthor',
    },
    {
      title: '发行方',
      dataIndex: 'collectionIssuer',
    },
  ];

  return (
    <div className='space-y-3'>
      <h1 className='text-xl'>
        客户: <span className='text-sm text-neutral-500'>(ID {userID})</span>
      </h1>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <div className='pl-12'>
              <p>存证时间: {record.evidenceTime}</p>
              <p>存证编码: {record.evidenceNo.toUpperCase()}</p>
              <p>区块链ID: {record.evidenceTxhash.toUpperCase()}</p>
            </div>
          ),
        }}
        dataSource={collectionList}
        loading={loading}
        rowKey={(record) => record['collectionId']}
        onChange={({ current, pageSize }) => {
          console.log(current, pageSize);
          setPage(current);
          setSize(pageSize);
        }}
        pagination={{
          defaultPageSize: size,
          showTotal: () => {
            return `共${total}条数据`;
          },
          current: page,
          total: total,
        }}
      />
    </div>
  );
};

export default MyCollection;
